import '../styles/index.scss';
import 'bootstrap';
import $ from 'jquery';

(function(){
  emailjs.init("user_qJ0zuc2mjEVewkzJpCnW7");
})();

$('#contactModal').on('show.bs.modal', function (event) {
  const button = $(event.relatedTarget);
  const subject = button.data('subject');
  const email = button.data('email');
  const modal = $(this);
  modal.find('.modal-body #subject').val(subject);
  if (email) {
    modal.find('.modal-body #to-email').val(email);
  }
});

$('#dismiss').on('click', function(){
  document.getElementById("contact-form").reset();
});

(function() {
  'use strict';
  window.addEventListener('load', function() {
    let contact = document.getElementsByClassName('needs-validation');
    let validation = Array.prototype.filter.call(contact, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        event.preventDefault();
        form.classList.add('was-validated');
        emailjs.sendForm('the_daily_accountant', 'the_daily_template', this)
          .then(function(response) {
            $('#contactModal').css('display', 'none').modal('hide');
            $('#thank-you').css('display', 'block').modal('show');
            setTimeout(function(){
              $('#thank-you').modal('hide');
            }, 2000);
          }, function(error) {
            $('#contactModal').css('display', 'none').modal('hide');
            $('#sorry-error').css('display', 'block').modal('show');
            setTimeout(function(){
              $('#sorry-error').modal('hide');
            }, 2000);
          });


      }, false);
    });
  }, false);
})();
